const convertEpochToDate = (epoch: number | undefined) => {
	if (epoch) {
		const date = new Date(epoch);
		date.setDate(date.getDate() + 1);
		return date.toLocaleDateString();
	}
	return epoch;
};

export { convertEpochToDate };
