import i18n from "i18next";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

i18n.use(backend)
	.use(detector)
	.use(initReactI18next)
	.init({
		resources: require("../locales/translation.json"),
		fallbackLng: process.env.REACT_APP_FALLBACK_LANGUAGE,
		interpolation: {
			escapeValue: false
		},
		detection: {
			order: ["querystring", "navigator"],
			lookupQuerystring: "lng"
		}
	});

export default i18n;
