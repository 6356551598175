import React, { useEffect, useCallback } from "react";
import { AxiosInstance } from "axios";
import { DetectionType } from "../interfaces";
import { convertEpochToDate } from "../utils";
import { Logger } from "@datadog/browser-logs";
import { useTranslation } from "react-i18next";
import axiosContext from "../contexts/axios.context";
import loggerContext from "../contexts/logger.context";
import { Box, Grid, Button, Typography } from "@mui/material";
import cameraContext, { CameraContextInterface } from "../contexts/camera.context";

const InternalDecoder = () => {
	const { t } = useTranslation();
	const logger: Logger | undefined = React.useContext(loggerContext);
	const axios: AxiosInstance | undefined = React.useContext(axiosContext);
	const camera: CameraContextInterface | null = React.useContext(cameraContext);
	const [decodedDatamatrix, setDecodedDatamatrix] = React.useState<any>(null);

	const sendImage = useCallback(async (): Promise<void> => {
		try {
			const image: string | undefined = camera?.takePicture();
			const response: any = await axios?.post("/recognize/userImage", {
				image,
				timeoutMs: 200,
				detectionThreshold: 100,
				detectionTypes: JSON.stringify([DetectionType.DataMatrix])
			});
			if (response?.dataMatrix?.payload) {
				setDecodedDatamatrix(response.dataMatrix.payload);
				return;
			}
			sendImage();
		} catch (error) {
			logger?.error(`Send Image Catch: ${error}`);
			setTimeout(() => sendImage(), 1000);
		}
	}, [axios, camera, logger]);

	useEffect(() => {
		sendImage();
	}, [sendImage]);

	return (
		<>
			<Box className="text-legend">
				{decodedDatamatrix ? (
					<Grid container justifyContent="center">
						<Box>
							<Typography>Value: {decodedDatamatrix?.data}</Typography>
							<Typography>Expiry: {convertEpochToDate(decodedDatamatrix?.expiry)}</Typography>
							<Typography>Gtin: {decodedDatamatrix?.gtin}</Typography>
							<Typography>Lot: {decodedDatamatrix?.lot}</Typography>
						</Box>
						<Button
							data-testid="restart-scanner-data-testid"
							color="success"
							variant="contained"
							onClick={() => {
								setDecodedDatamatrix(null);
								sendImage();
							}}
						>
							{t("internalDecoder.restartScanner")}
						</Button>
					</Grid>
				) : (
					<>
						<Typography variant="h3" data-testid="title-testid">
							{t("internalDecoder.title")}
						</Typography>
						<Typography variant="h3" data-testid="subtitle-testid">
							{t("internalDecoder.subtitle")}
						</Typography>
					</>
				)}
			</Box>

			<Box className={decodedDatamatrix ? "video-overlay" : "video-overlay scan-bar"}>
				<Box className={decodedDatamatrix ? "successful-scanning" : "corners"}>
					<Box />
					{decodedDatamatrix && (
						<img className="check-mark-image" src="/images/check-mark.png" alt="check mark" />
					)}
				</Box>
			</Box>
		</>
	);
};

export default InternalDecoder;
