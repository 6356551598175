export interface OCR {
	name: string;
	textToMatch: string[];
}

export enum DetectionType {
	Boxes = "box",
	Faces = "faces",
	QrCode = "qrCode",
	Text = "text",
	DataMatrix = "dataMatrix"
}
