import { Box } from "@mui/material";
import { Logger } from "@datadog/browser-logs";
import DialogComponent from "../components/Dialog";
import loggerContext from "../contexts/logger.context";
import React, { useContext, useEffect } from "react";

export enum Permissions {
	DENIED = "denied",
	ACCEPTED = "accepted",
	NOT_ANSWERED = "not_answered"
}

interface UserCameraProps {
	children: React.ReactElement;
}

const UserCamera = ({ children }: UserCameraProps) => {
	const logger: Logger | undefined = useContext(loggerContext);
	const [readyForScanning, setReadyForScanning] = React.useState<boolean>(false);
	const [permissionStatus, setPermissionStatus] = React.useState<Permissions>(Permissions.NOT_ANSWERED);
	const videoRef = React.useRef<HTMLVideoElement>(document.getElementById("video") as HTMLVideoElement);
	const canvasRef = React.useRef<HTMLCanvasElement>(document.getElementById("canvas") as HTMLCanvasElement);

	useEffect(() => {
		const constraints = {
			video: {
				aspectRatio: 1,
				facingMode: {
					ideal: "environment"
				}
			}
		};

		navigator.mediaDevices
			.getUserMedia(constraints)
			.then((stream) => {
				videoRef.current.srcObject = stream;
				setPermissionStatus(Permissions.ACCEPTED);
			})
			.catch((error) => {
				logger?.error(`Navigator getUserMedia Catch: ${error}`);
				//This state is in charge of displying the modal
				setPermissionStatus(Permissions.DENIED);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const takePicture = (): string | undefined => {
	// 	const canvas = canvasRef.current;
	// 	const video = document.querySelector("video");
	// 	const context = canvas.getContext("2d");
	//
	// 	if (!context || !video) {
	// 		return;
	// 	}
	//
	// 	canvas.width = video.offsetWidth || video.videoWidth || video.width;
	// 	canvas.height = video.offsetHeight || video.videoHeight || video.height;
	//
	// 	context.drawImage(video, 0, 0, canvas.width, canvas.height);
	//
	// 	return canvas.toDataURL("image/jpeg");
	// };

	useEffect(() => {
		const timer = setTimeout(() => {
			// Scanning state will also depend on the acceptance of using camera
			if (!readyForScanning && permissionStatus === Permissions.ACCEPTED) {
				setReadyForScanning(true);
			}
		}, parseInt(process.env.REACT_APP_CAMERA_STARTUP_TIMER_MS || "0"));
		return () => {
			clearTimeout(timer);
		};
	}, [readyForScanning, permissionStatus]);

	return (
		<>
			{(() => {
				if (permissionStatus === Permissions.NOT_ANSWERED) {
					return <Box className="prompt-desktop-overlay" data-testid="prompt-desktop-overlay-data-testid" />;
				} else if (permissionStatus === Permissions.DENIED) {
					return <DialogComponent handleClose={() => setPermissionStatus(Permissions.ACCEPTED)} />;
				} else if (permissionStatus === Permissions.ACCEPTED && videoRef.current && canvasRef.current) {
					return (
						<Box className="validator-app-container" data-testid="validator-app-container-data-testid">
							<Box className="validator-app" data-testid="validator-app-data-testid">
								{children}
							</Box>
						</Box>
					);
				}
			})()}
			<video id="video" ref={videoRef} muted autoPlay playsInline />
		</>
	);
};

export default UserCamera;
