import React, { useRef, useEffect } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { Logger } from "@datadog/browser-logs";
import { useTranslation } from "react-i18next";
import DialogComponent from "../components/Dialog";
import loggerContext from "../contexts/logger.context";
import { ImagingScanner } from "@emed/ui-lib/lib/components/ImagingScanner";

export enum Permissions {
	DENIED = "denied",
	ACCEPTED = "accepted",
	NOT_ANSWERED = "not_answered"
}

const BoxValidator = () => {
	const { t } = useTranslation();
	const { sessionId } = useParams();
	const logger: Logger | undefined = React.useContext(loggerContext);
	const [isSession, setIsSession] = React.useState<boolean>(false);
	const [showCheckMark, setShowCheckMark] = React.useState<boolean>(false);
	const [readyForScanning, setReadyForScanning] = React.useState<boolean>(false);
	const videoRef = useRef<HTMLVideoElement>(document.getElementById("video") as HTMLVideoElement);
	const [permissionStatus, setPermissionStatus] = React.useState<Permissions>(Permissions.NOT_ANSWERED);

	useEffect(() => {
		const constraints = {
			video: {
				aspectRatio: 1,
				facingMode: {
					ideal: "environment"
				}
			}
		};

		navigator.mediaDevices
			.getUserMedia(constraints)
			.then((stream) => {
				videoRef.current.srcObject = stream;
				setPermissionStatus(Permissions.ACCEPTED);
			})
			.catch((error) => {
				logger?.error(`Navigator getUserMedia Catch: ${error}`);
				// This state is in charge of displying the modal
				setPermissionStatus(Permissions.DENIED);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			// Scanning state will also depend on the acceptance of using camera
			if (!readyForScanning && permissionStatus === Permissions.ACCEPTED) {
				setReadyForScanning(true);
			}
		}, parseInt(process.env.REACT_APP_CAMERA_STARTUP_TIMER_MS || "0"));
		return () => {
			clearTimeout(timer);
		};
	}, [readyForScanning, permissionStatus]);

	const onCodeScanned = (data: any) => {
		setShowCheckMark(true);
	};

	const onSessionCreated = (data: any) => {
		if (data?.nextProcedureURI) {
			setIsSession(true);
			setTimeout(
				() => window.location.assign(data.nextProcedureURI),
				parseInt(process.env.REACT_APP_CORE_SESSION_REDIRECT_TIMER_MS || "0")
			);
		} else {
			setShowCheckMark(false);
		}
	};

	return (
		<>
			{(() => {
				if (permissionStatus === Permissions.NOT_ANSWERED) {
					return <Box className="prompt-desktop-overlay" data-testid="prompt-desktop-overlay-data-testid" />;
				} else if (permissionStatus === Permissions.DENIED) {
					return <DialogComponent handleClose={() => setPermissionStatus(Permissions.ACCEPTED)} />;
				} else if (permissionStatus === Permissions.ACCEPTED && videoRef.current) {
					return (
						<Box className="validator-app-container" data-testid="validator-app-container-data-testid">
							<Box className="validator-app" data-testid="validator-app-data-testid">
								<Box className="text-legend">
									<h3 data-testid="title-testid">{!isSession && t("boxValidator.title")}</h3>
									<p data-testid="subtitle-testid">{!isSession && t("boxValidator.subtitle")}</p>
								</Box>
								<Box className={!showCheckMark ? "video-overlay scan-bar" : "video-overlay"}>
									<Box className={showCheckMark ? "successful-scanning" : "corners"}>
										<Box />
										{showCheckMark && (
											<img
												className="check-mark-image"
												src="/images/check-mark.png"
												alt="check mark"
											/>
										)}
									</Box>
								</Box>
							</Box>
						</Box>
					);
				}
			})()}
			<ImagingScanner
				config={{
					enableScan: !showCheckMark,
					videoElement: "video",
					baseUrl: process.env.REACT_APP_IMAGING_API_BASE_URL || "",
					onCodeScanned,
					onSessionCreated,
					sessionId,
					processCoreSession: true,
					lastAttemptTimeoutMs: 10000,
					lastAttemptScopeDefault: "binaxnow"
				} as any}
			></ImagingScanner>
			<video id="video" ref={videoRef} muted autoPlay playsInline />
		</>
	);
};

export default BoxValidator;
