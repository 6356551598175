import React from "react";
import { AxiosInstance } from "axios";
import { DetectionType } from "../interfaces";
import { convertEpochToDate } from "../utils";
import { Logger } from "@datadog/browser-logs";
import { useTranslation } from "react-i18next";
import axiosContext from "../contexts/axios.context";
import loggerContext from "../contexts/logger.context";
import { Box, Grid, Button, Typography } from "@mui/material";

const InternalDecoderInput = () => {
	const { t } = useTranslation();
	const logger: Logger | undefined = React.useContext(loggerContext);
	const axios: AxiosInstance | undefined = React.useContext(axiosContext);
	const [decodedDatamatrix, setDecodedDatamatrix] = React.useState<any>(null);

	const sendImage = async (image: string) => {
		try {
			image = image.replace("png", "jpeg");
			const response: any = await axios?.post("/recognize/userImage", {
				image,
				detectionThreshold: 100,
				detectionTypes: JSON.stringify([DetectionType.DataMatrix]),
			});
			if (response?.dataMatrix?.payload) {
				setDecodedDatamatrix(response.dataMatrix.payload);
			}
		} catch (error) {
			logger?.error(`Send Image Catch: ${error}`);
		}
	};

	const handleInputChange = (event: any) => {
		let files = event.target.files;
		let reader = new FileReader();
		reader.readAsDataURL(files[0]);

		reader.onload = (e: any) => {
			sendImage(e.target.result);
		};
	};

	return (
		<Box className="validator-app-container">
			<Box className={"validator-app"}>
				<Box className="text-legend">
					<Grid container justifyContent="center">
						<Typography variant="h3" data-testid="title-testid">
							{t("internalDecoderInput.uploadFileToScan")}
						</Typography>
					</Grid>

					<Grid container justifyContent="center">
						{decodedDatamatrix && (
							<Box>
								<Typography data-testid="datamatrix-value-data-testid">Value: {decodedDatamatrix?.data}</Typography>
								<Typography>Expiry: {convertEpochToDate(decodedDatamatrix?.expiry)}</Typography>
								<Typography>Gtin: {decodedDatamatrix?.gtin}</Typography>
								<Typography>Lot: {decodedDatamatrix?.lot}</Typography>
							</Box>
						)}
					</Grid>

					<Grid container justifyContent="center">
						<Button color="success" variant="contained" component="label">
							{t("internalDecoderInput.uploadFile")}
							<input
								hidden
								type="file"
								onChange={handleInputChange}
								accept="image/png, image/jpeg"
								data-testid="upload-file-data-testid"
							/>
						</Button>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};

export default InternalDecoderInput;
