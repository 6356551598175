import { Box, Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";

type DialogProps = {
	open?: boolean;
	handleClose: () => void;
};

const DialogComponent = (props: DialogProps) => {
	const { handleClose, open = true } = props;
	const { t } = useTranslation();
	return (
		<Dialog onClose={handleClose} open={open} className="dialog">
			<Box className="camera-permission">
				<img src="/images/alert.png" alt="alert" />
				<h3 data-testid="dialog-title-testid">{t("dialog.title")}</h3>
				<p>{t("dialog.subtitle")}</p>
				<br />
				<p className="dialog-legend-desktop">
					{t("dialog.question")}
					<br className="dialog-break-desktop" />
					<a href="https://www.emed.com/contact">{t("dialog.help")}</a>
				</p>
			</Box>
		</Dialog>
	);
};

export default DialogComponent;
