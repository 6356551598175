import { useRef } from "react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import AxiosContext from "../contexts/axios.context";

interface AxiosInstanceProviderProps {
	config: AxiosRequestConfig;
	children: React.ReactElement;
}

const AxiosInstanceProvider = ({ config, children }: AxiosInstanceProviderProps) => {
	const instanceRef = useRef(axios.create(config));

	const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
		Object.assign(config, { data: new URLSearchParams(config.data) });
		return config;
	};

	const onRequestError = (error: AxiosError): Promise<AxiosError> => {
		console.error(`[request error] [${JSON.stringify(error)}]`);
		return Promise.reject(error);
	};

	instanceRef.current.interceptors.request.use(onRequest, onRequestError);

	const onResponse = (response: AxiosResponse): AxiosResponse => {
		return response?.data?.this === "succeeded" && response.data.with;
	};

	const onResponseError = (error: AxiosError): Promise<AxiosError> => {
		console.error(`[response error] [${JSON.stringify(error)}]`);
		return Promise.reject(error);
	};

	instanceRef.current.interceptors.response.use(onResponse, onResponseError);

	return <AxiosContext.Provider value={instanceRef.current}>{children}</AxiosContext.Provider>;
};

export default AxiosInstanceProvider;
