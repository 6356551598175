import React, { useEffect } from "react";
import BoxValidator from "./pages/BoxValidator";
import { Route, Routes } from "react-router-dom";
import UserCamera from "./components/UserCamera";
import { datadogLogs } from "@datadog/browser-logs";
import InternalDecoder from "./pages/InternalDecoder";
import AxiosProvider from "./providers/AxiosProvider";
import loggerContext from "./contexts/logger.context";
import InternalDecoderInput from "./pages/InternalDecoderInput";

const App = () => {
	useEffect(() => {
		initDatadog();
	}, []);

	const initDatadog = (): void => {
		const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;

		if (!clientToken) {
			console.error("Datadog Client Token is missing");
			return;
		}

		datadogLogs.init({
			clientToken,
			sampleRate: 100,
			site: "datadoghq.com",
			forwardErrorsToLogs: false,
			env: process.env.REACT_APP_ENV_SHORT_STACK,
			service: process.env.REACT_APP_PROJECT_NAME,
			version: process.env.REACT_APP_PROJECT_VERSION
		});
	};

	return (
		<AxiosProvider
			config={{
				baseURL: process.env.REACT_APP_IMAGING_API_BASE_URL,
				headers: { "Content-Type": "application/x-www-form-urlencoded" }
			}}
		>
			<loggerContext.Provider value={datadogLogs?.logger}>
				<Routes>
					<Route
						path="/:sessionId"
						element={
							<BoxValidator />
						}
					/>
					<Route
						path="/internal-decoder"
						element={
							<UserCamera>
								<InternalDecoder />
							</UserCamera>
						}
					/>
					<Route path="/internal-decoder-input" element={<InternalDecoderInput />} />
				</Routes>
			</loggerContext.Provider>
		</AxiosProvider>
	);
};

export default App;
